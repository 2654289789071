import React from "react";
import './Home.css';
import AppNavbar from "../Navbar/AppNavbar";
import HomeSlider from "../Slider/HomeSlider";
import Footer from "../Footer/Footer";
function Home(){
    return(
        <>
        <AppNavbar/>
        <HomeSlider/>
        <div className="page-content">
  {/* Why Choose Us Start */}
  <section className="section-lg why-choose-section-seven">
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div className="pbmit-heading-subheading-style-2 text-white">
            {/* <h4 className="pbmit-subtitle">WHY CHOOSE US</h4> */}
            <h2 className="pbmit-title">
            Sr Advisor
            </h2>
          </div>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-4">
              <ul className="list-group list-group-style-1 list-group-borderless">
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Council for Indian Economic Affairs</span>
                </li>
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Nyaya Sutras</span>
                </li>
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Arth Shastram</span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-group list-group-style-1 list-group-borderless">
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Strategy Council for Indian Logistics, EPC & Infrastructure</span>
                </li>
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Sanskriti Sangyan</span>
                </li>
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Star Capital</span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-group list-group-style-1 list-group-borderless">
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Council for Indian Culture, Art & Film's</span>
                </li>
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Astro Guru</span>
                </li>
                <li className="list-group-item">
                  <i className="themifyicon ti-arrow-right" />
                  <span>Avdhi Vikas Sansthan</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Why Choose Us End */}
  {/* About Start */}
  <section className="about-section-seven">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="about-seven-imgbox">
            <figure>
              <img
                src="assest\images\cwiil-verticals.jpg"
                className="img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="about-seven-content">
            <div className="pbmit-heading-subheading-style-2">
              <h4 className="pbmit-subtitle">WHY CHOOSE US</h4>
              <h2 className="pbmit-title">
              Welcome to the Official Website Of Dr Pradeep Singh
              </h2>
            </div>
            <div className="pbmit-firstlater">
              <p className="mb-3">
                Markets &amp; Securities Far far away, behind the word, far from
                the countries Vokalia and Consonantia.
              </p>
            </div>
            <p>
              A wonderful serenity taketaken possession of entire my entire
              soul.
            </p>
            <div className="about-seven-ihbox">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="pbmit-ihbox-style-7">
                    <div className="pbmit-ihbox-inner">
                      <div className="pbmit-ihbox-icon pbmit-icon-skincolor" />
                      <div className="pbmit-ihbox-contents">
                        <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                          <div className="pbmit-vc_cta3_content-container">
                            <div className="pbmit-vc_cta3-content">
                              <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                                <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                                  <h2 className="pbmit-custom-heading">
                                    VC & MD
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pbmit-cta3-content-wrapper">
                         CWILL Group
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-seven-inner">
              <div className="row align-items-center">
                <div className="col-2 col-lg-2 p-0">
                  <div className="about-seven-single-img">
                    <figure>
                      <img
                        src="assest/images/homepage-7/img-01.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-8 col-lg-5">
                  {/* <figure>
                    <img
                      src="assest/images/homepage-7/sign.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure> */}
                  <h5>Dr.Pradeep Singh</h5>
                </div>
                <div className="col-12 col-lg-5">
                  <a
                    href="about-us.html"
                    className="pbmit-btn pbmit-btn-global"
                  >
                    Get Touch
                    <i className="themifyicon ti-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* About End */}
  {/* Ihbox Start */}
  <section className="section-lgb">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="pbmit-ihbox-style-8">
            <div className="pbmit-ihbox-inner">
              <div className="pbmit-ihbox-heading-with-icon">
                <div className="pbmit-ihbox-icon ">
                  <div className="pbmit-ihbox-icon-wrapper">
                    <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-balance" />
                  </div>
                </div>
                <div className="pbmit-ihbox-heading">
                  <div className="pbmit-ihbox-big-number-text">01</div>
                  <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                    <div className="pbmit-vc_cta3_content-container">
                      <div className="pbmit-vc_cta3-content">
                        <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                          <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                            <h2 className="pbmit-custom-heading ">
                            VC & MD,Chief Editor
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-ihbox-contents">
                    <div className="pbmit-cta3-content-wrapper">
                    World News
                    (Asia Pacific Region)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="pbmit-ihbox-style-8 ihbox-seven-second-style">
            <div className="pbmit-ihbox-inner">
              <div className="pbmit-ihbox-heading-with-icon">
                <div className="pbmit-ihbox-icon ">
                  <div className="pbmit-ihbox-icon-wrapper">
                    <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-suitcase" />
                  </div>
                </div>
                <div className="pbmit-ihbox-heading">
                  <div className="pbmit-ihbox-big-number-text">02</div>
                  <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                    <div className="pbmit-vc_cta3_content-container">
                      <div className="pbmit-vc_cta3-content">
                        <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                          <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                            <h2 className="pbmit-custom-heading ">
                            Sr Partner
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-ihbox-contents">
                    <div className="pbmit-cta3-content-wrapper">
                    Legal Eagles Law Firm
                    Hon'ble Supreme Court of India
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="pbmit-ihbox-style-8 ihbox-seven-last-style">
            <div className="pbmit-ihbox-inner">
              <div className="pbmit-ihbox-heading-with-icon">
                <div className="pbmit-ihbox-icon ">
                  <div className="pbmit-ihbox-icon-wrapper">
                    <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-legal-document" />
                  </div>
                </div>
                <div className="pbmit-ihbox-heading">
                  <div className="pbmit-ihbox-big-number-text">03</div>
                  <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                    <div className="pbmit-vc_cta3_content-container">
                      <div className="pbmit-vc_cta3-content">
                        <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                          <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                            <h2 className="pbmit-custom-heading ">
                            Promoter
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-ihbox-contents">
                    <div className="pbmit-cta3-content-wrapper">        CEMA
                      (Center of Excellence For Mediation And Arbitration)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Testimonial End */}
  {/* Might Be */}

  {/* <section className="section-md blog-seven">
    <div className="container">
      <div className="row g-0">
        <div className="col-md-12 col-lg-8">
        </div>
        <div className="col-md-1 offset-1 col-lg-4">
          <div className="form-seven-box">
            <div className="pbmit-heading-subheading-style-2"> 
              <h2 className="pbmit-title text-white">Quick Contact</h2>
            </div>
            <form>
              <div className="row g-0">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="your-name"
                    className="form-control"
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="email"
                    name="your-email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    name="your-phone"
                    className="form-control"
                    placeholder="Phone"
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="message"
                    cols={40}
                    rows={4}
                    className="form-control"
                    placeholder="Message"
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="col-md-12 col-lg-12">
                  <input
                    className="pbmit-btn pbmit-btn-global w-100"
                    type="submit"
                    defaultValue="SEND MESSAGE"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="about-seven-imgbox">
            <figure>
              <img
                src="assest\images\i.jpg"
                className="img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section> */}

  <section>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src="assest/images/i.jpg" 
            className="img-fluids"
            alt="" />
          </div>
          <div className="col-md-6">
            <div className="contact-form">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">PLEASE Fill Form</h4>
                <h2 className="pbmit-title">Do You Have Any Questions?</h2>
              </div>
              <form
                method="post"
                id="contact-form"
                action="https://attorco-demo.pbminfotech.com/html-demo/send.php"
              >
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="text"
                      name="number"
                      className="form-control"
                      placeholder="Phone"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="message"
                      cols={40}
                      rows={4}
                      className="form-control"
                      placeholder="Message"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <button
                      type="submit"
                      className="pbmit-btn pbmit-btn-global pbmit-btn-shape-round w-100"
                    >
                      <i className="form-btn-loader fa fa-circle-o-notch fa-spin fa-fw margin-bottom d-none" />
                      SEND MESSAGE
                    </button>
                  </div>
                  <div className="col-md-12 col-lg-12 message-status" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  {/* Blog End */}
</div>

<Footer/>


        </>
    )
}
export default Home;