import React from 'react'
import './Media.css'
import AppNavbar from '../Navbar/AppNavbar';
import Footer from "../Footer/Footer";

const ContactUs = () => {
    return (
        <>
            
            <AppNavbar/>


            <>
  <div className="pbmit-title-bar-wrapper">
    <div className="container">
      <div className="pbmit-title-bar-content">
        <div className="pbmit-title-bar-content-inner">
          <div className="pbmit-tbar">
            <div className="pbmit-tbar-inner container">
              <h1 className="pbmit-tbar-title"> Contacts</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Title Bar End*/}
  {/* Page Content */}
  <div className="page-content">
    {/* Ihbox */}
    <section className="contact-ihbox-section">
      <div className="container">
        <div className="contact-ihbox">
          <div className="row g-0">
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-4">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon pbmit-icon-skincolor" />
                  <div className="pbmit-ihbox-contents">
                    <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                      <div className="pbmit-vc_cta3_content-container">
                        <div className="pbmit-vc_cta3-content">
                          <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                            <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                              <h2 className="pbmit-custom-heading">Address</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pbmit-cta3-content-wrapper">
                      1/134 Vivek Khand, Gomti Nagar,  
                      <br />
                      Lucknow 226010. 
                      <p />
                      <p>
                        <a href="#">View On Google Map</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-4 pbmit-textcolor-white">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon pbmit-icon-skincolor" />
                  <div className="pbmit-ihbox-contents">
                    <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                      <div className="pbmit-vc_cta3_content-container">
                        <div className="pbmit-vc_cta3-content">
                          <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                            <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                              <h2 className="pbmit-custom-heading">Phone</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pbmit-cta3-content-wrapper">
                      A wonderful serenity has taken possession of my entire
                      soul, like these.
                      <p />
                      <p>
                        <a href="tel:+91.93366.13039">+91.93366.13039</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-4">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon pbmit-icon-skincolor" />
                  <div className="pbmit-ihbox-contents">
                    <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                      <div className="pbmit-vc_cta3_content-container">
                        <div className="pbmit-vc_cta3-content">
                          <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                            <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                              <h2 className="pbmit-custom-heading">Email</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pbmit-cta3-content-wrapper">
                      A wonderful serenity has taken possession of my entire
                      soul, like these.
                      <p />
                      <p>
                        <a href="#">
                          <span
                            className="__cf_email__"
                            data-cfemail="49242820250925283e302c3b2a26672a2624"
                          >
                            info@pradeepsingh.in
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Ihbox End */}
    {/* Contact Form */}
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-details">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">GET IN TOUCH</h4>
                <h2 className="pbmit-title">Contact Details</h2>
              </div>
              <p>
                If you are interested in working with us then please drop us a
                line, we would love to hear from you.
              </p>
              <h5>Global Headquarters</h5>
              <p>
                Overseeing all Group Companies Global Operations. Level # 2 , Raheja Center Point , 294 C.S.T. Road, Off. Bandra Kurla Complex, Near Mumbai University, Santa Cruz [ East ] , Mumbai - 400098
              </p>
              <h5>Call Us Today</h5>
              <p>
                +933 <strong>661 3040</strong>
              </p>
              <h5>Fax Us</h5>
              <p>
                +457 <strong>014 14 98</strong>
              </p> 
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">PLEASE Fill Form</h4>
                <h2 className="pbmit-title">Do You Have Any Questions?</h2>
              </div>
              <form
                method="post"
                id="contact-form"
                action="https://attorco-demo.pbminfotech.com/html-demo/send.php"
              >
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="text"
                      name="number"
                      className="form-control"
                      placeholder="Phone"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="message"
                      cols={40}
                      rows={4}
                      className="form-control"
                      placeholder="Message"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <button
                      type="submit"
                      className="pbmit-btn pbmit-btn-global pbmit-btn-shape-round w-100"
                    >
                      <i className="form-btn-loader fa fa-circle-o-notch fa-spin fa-fw margin-bottom d-none" />
                      SEND MESSAGE
                    </button>
                  </div>
                  <div className="col-md-12 col-lg-12 message-status" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Contact Form End */}
    {/* Iframe */}

    <section>
      <div className="contact-section-iframe">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.3996851704755!2d80.9948920755561!3d26.859039762394936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be2c89aaaaaa7%3A0xe4bafbaa2fc36bdf!2s1%2C%20Vivek%20Khand%202%2C%20Gomti%20Nagar%2C%20Lucknow%2C%20Uttar%20Pradesh%20226010!5e0!3m2!1sen!2sin!4v1702629885593!5m2!1sen!2sin" width="800" height="600"
        />
      </div>
    </section>
    {/* Iframe End */}
  </div>
  {/* Page Content End */}
</>

      

            <Footer />
        </>
    )
}

export default ContactUs;