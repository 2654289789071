import React from 'react'
import './Media.css'
import AppNavbar from '../Navbar/AppNavbar';
import Footer from "../Footer/Footer";

const AstroGuru = () => {
  return (
    <>
      <AppNavbar/>

      {/* *****************Jagriti************** */}
      
      <img src="assest/images/backchild.jpg" width="100%" className='backchildimg' alt="" />
      <section className="ihbox-section-two">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-5">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                    <div className="pbmit-vc_cta3_content-container">
                      <div className="pbmit-vc_cta3-content">
                        <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                          <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                            <h2 className="pbmit-custom-heading">
                              For Online Global Enquiry
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                    <img src="assest/images/phone-call.png" alt="" className='icon' />
                    <a href="tel:+45.5148.3608" className='email'>+ 45.5148.3608</a>{" "}
                    <div className="pbmit-ihbox-contents">
                      <img src="assest/images/fax.png" alt="" className='icon' />
                      <a href="tel:+45.5148.3608" className='email'>+ 45.7014.1498</a>{" "} <br />
                      <img src="assest/images/empty-email.png" alt="" className='icon' />
                      <a href="info@cwiilgroup.com" className='email'>info@cwiilgroup.com</a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-5 pbmit-textcolor-white">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                    <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                      <h2 className="pbmit-custom-heading">
                        For Franchise and Distributorship Enquiry
                      </h2>
                    </div>
                    <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                      <img src="assest/images/phone-call.png" alt="" className='icon' id="imgcol"/>
                      <a href="tel:+45.5148.3608" className='email text-white'>+ 91.93366.13039</a>{" "}
                      <div className="pbmit-ihbox-contents">
                        <img src="assest/images/empty-email.png" alt="" className='icon' id="imgcol"/>
                        <a href="info@cwiilgroup.com" className='email text-white'>sales@cwiilgroup.com</a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-5 pbmit-textcolor-white">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                    <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                      <h2 className="pbmit-custom-heading">
                        Investors Contact On
                      </h2>
                    </div>
                    <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                      <img src="assest/images/phone-call.png" alt="" className='icon' id="imgcol"/>
                      <a href="tel:+45.5148.3608" className='email text-white'>+ 91.93366.13040</a>{" "}
                      <div className="pbmit-ihbox-contents">
                        <img src="assest/images/empty-email.png" alt="" className='icon' id="imgcol"/>
                        <a href="info@cwiilgroup.com" className='email text-white'>investments@cwiilgroup.com</a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ihbox End */}
      {/* About Start */}
      <section className="section-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="about-two-img-box">
                <figure>
                  <img
                    src="assest/images/media.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </figure>
                <div className="pbmit-fidbox-style-2">
                  <div className="pbmit-fld-contents">
                    <div className="pbmit-ihbox-icon pbmit-large-icon">
                      <div className="pbmit-sbox-icon-wrapper">
                        <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-balance" />
                      </div>
                    </div>
                    <div className="pbmit-fld-contents-wrap">
                      <div className="pbmit-fid-inner">
                        <span
                          data-appear-animation="animateDigits"
                          data-from={0}
                          data-to={98}
                          data-interval={5}
                          className="numinate"
                        >
                          98
                        </span>
                        <sub>%</sub>
                      </div>
                      <h3 className="pbmit-fid-title">
                        <span>
                          SUCCESS RATE
                          <br />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="about-two-content">
                <div className="pbmit-heading-subheading">
                  <h2 className="pbmit-title">Astro Consultancy</h2>
                </div>
                <div className="pbmit-firstlater">
                  <p>
                    Well &amp; experienced Astrology expert can predict your future and tell you the remedies for your problems. We have panel of Astrologers (well experience in astrology consultation) to advice you remedies for your problems. Online horoscope and online marriage compatibility. The site also offers information on palmistry, Vaastushastra, astrological books, graphology, gemstones and numerology. &nbsp;
                  </p>
                </div>
                {/* ******************* */}
              <h5 className="pbmit-title">Rudraksh</h5>
              <p>All Rudraksha beads are directly collected from our own rudraksha farm as well as purchased from farmers directly .All our Products are Genuine, good in shape,size, lusture and properly blessed.With our deep scientific research and huge experiences on Rudraksha and other divine items; we recommend, blessed, energised and configure these Rudrakshas and Divine Items so that the customer get maximum and optimal benefits . &nbsp;
              </p>

                {/* ***************** */}
                {/****************** */}

              </div>
            </div>
          </div>
        </div>
        {/* ***************DOWN******************** */}
        <div className="container">
          <div className="row">
           
            {/* ******** */}
              <h5 className="pbmit-title">Ratna</h5>
              <p>Astroguru helps their users to provide the best quality, genuine, appropriate gemstones of certain weights that could be worn in forms of rings or pendent, completely furnished and guaranteed. Gem stones or birth stones or lucky stones affect the human body through their measured radiations.They increase the psychic powers of an individual and ward off the ill-effects of planets by their curative powers. &nbsp;
              </p>
            {/* ******** */}
            {/* ******** */}
              <h5 className="pbmit-title">Fengshui</h5>
              <p>Feng Shui is an ancient art and science developed over 3,000 years ago in China. It is a complex body of knowledge that reveals how to balance the energies of any given space to assure the health and good fortune for people inhabiting it. Feng means wind and shui means water. In Chinese culture wind and water are associated with good health, thus good feng shui came to mean good fortune, while bad feng shui means bad luck, or misfortune. &nbsp;
              </p>
            {/* ******** */}
            {/* ******** */}
              <h5 className="pbmit-title">Karmkand</h5>
              <p>Karmkand refers to ritual services performed by swamis or religious Brahmins for the welfare of society. One can overcome from the Planetary problems through Karmkand like as Marriage problems, Health problems, wealth problems, business problems etc. As per the state of planets in Horoscope People face different types of problems in life like kalsarp dosh, pitra dosh, manglik dosh, chandaal dosh etc. &nbsp;
              </p>
            {/* ******** */}

          </div>
        </div>
        {/* ***************DOWN***************** */}
      </section>


      <section className="section-lg pbmit-bg-color-blackish">
        <div className="container">
          <div className="pbmit-heading-subheading text-center text-white">
            <h4 className="pbmit-subtitle">Fee As per the package
            </h4>
            <h2 className="pbmit-title">Core Values</h2>
          </div>
          <div className="service-arrow swiper-btn-custom d-flex flex-row-reverse">
            <div
              className="swiper-slider"
              data-arrows-class="service-arrow"
              data-loop="true"
              data-autoplay="true"
              data-dots="false"
              data-arrows="true"
              data-columns={4}
              data-margin={30}
              data-effect="slide"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {/* Slide1 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-service-content">
                              <p>
                                KAIZEN, which in layman terms means Constant Improvement , is our driving force, as Change is the only constant in the world. Adherence to Ethical, Legal, Socio-Environmental Values while Getting To and Addressing the Root of the Clients’ [ Government, Private or Non Profit Organisation ] Requirements, and after Proper Evaluation an Integrated Approach is taken to Ensure Results on a One-Time or Continuation Basis, as the External and Internal Business Environment is Dynamic or Changing Constantly.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide2 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-law-book" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Education Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide3 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-legal" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Business Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide4 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-briefcase" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Real Estate law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide5 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-lawyer-1" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Civil Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide6 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-gun" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Criminal Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide7 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-team" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Car Accident
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide8 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-lawyer-3" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Employment Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide9 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-legal-document" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Banking Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* **************Jagriti******************** */}
      {/* Service End */}
      {/* About Us Start */}
      <section className="about-us-two">
        <div className="container">
          <div className="about-us-section-two">
            <div className="row">
              <div className="col-md-8">
                <div className="pbmit-ihbox-style-1">
                  <div className="pbmit-ihbox-inner">
                    <div className="pbmit-ihbox-heading-with-icon">
                      <div className="pbmit-ihbox-icon ">
                        <div className="pbmit-ihbox-icon-wrapper">
                          <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-lawyer" />
                        </div>
                      </div>
                      <div className="pbmit-ihbox-heading">
                        <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                          <div className="pbmit-vc_cta3_content-container">
                            <div className="pbmit-vc_cta3-content">
                              <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                                <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                                  <h2 className="pbmit-custom-heading ">
                                    Let us help you! Call Now
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pbmit-ihbox-contents">
                          <div className="pbmit-cta3-content-wrapper">
                            Get a free case evaluation. Call now +1-800-234-1345
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="about-us-two-btn">
                  <a
                    href="homepage-2.html"
                    className="pbmit-btn pbmit-btn-blackish"
                  >
                    Get In Touch
                    <i className="themifyicon ti-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default AstroGuru;