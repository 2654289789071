import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home/Home';
import AboutUs from './Expertise/AboutUs';
import ContactUs from './Expertise/ContactUs';
import Media from './Expertise/Media';
import Legal from './Expertise/Legal';
import PestControl from './Expertise/PestControl'
import TechLabs from './Expertise/TechLabs'
import AstroGuru from './Expertise/AstroGuru';
import Consultancy from './Expertise/Consultancy'
import NewAlternative from './Expertise/NewAlternative'
import Investments from './Expertise/Investments'
import InfrastructureProject from './Expertise/InfrastructureProject'


function App() {
  return (
        <>    
    <BrowserRouter>
   
      <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/aboutus" element={<AboutUs/>}/>
          <Route path="/contactus" element={<ContactUs/>}/>
          <Route path="/legal" element={<Legal/>}/>
          <Route path="/Media" element={<Media/>}/>
          <Route path="/pestcontrol" element={<PestControl/>}/>  
          <Route path="/techlabs" element={<TechLabs/>}/>
          <Route path="/astroguru" element={<AstroGuru/>}/>  
          <Route path="/consultancy" element={<Consultancy/>}/> 
          <Route path="/newalternative" element={<NewAlternative/>}/>
          <Route path="/investments" element={<Investments/>}/>
          <Route path="/infrastructureproject" element={<InfrastructureProject/>}/>         

      </Routes>

    </BrowserRouter>
    </>
  );
}

export default App;





