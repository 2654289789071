import React from 'react'
import './Media.css'
import AppNavbar from '../Navbar/AppNavbar';
import Footer from "../Footer/Footer";

const Legal = () => {
  return (
    <>
      <AppNavbar/>
      
      {/* *****************Jagriti************** */}
      <img src="assest/images/backchild.jpg" width="100%" className='backchildimg' alt="" />
      <section className="ihbox-section-two">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-5">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                    <div className="pbmit-vc_cta3_content-container">
                      <div className="pbmit-vc_cta3-content">
                        <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                          <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                            <h2 className="pbmit-custom-heading">
                              For Online Global Enquiry
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                    <img src="assest/images/phone-call.png" alt="" className='icon' />
                    <a href="tel:+45.5148.3608" className='email'>+ 45.5148.3608</a>{" "}
                    <div className="pbmit-ihbox-contents">
                      <img src="assest/images/fax.png" alt="" className='icon' />
                      <a href="tel:+45.5148.3608" className='email'>+ 45.7014.1498</a>{" "} <br />
                      <img src="assest/images/empty-email.png" alt="" className='icon' />
                      <a href="info@cwiilgroup.com" className='email'>info@cwiilgroup.com</a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-5 pbmit-textcolor-white">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                    <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                      <h2 className="pbmit-custom-heading">
                        For Franchise and Distributorship Enquiry
                      </h2>
                    </div>
                    <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                      <img src="assest/images/phone-call.png" alt="" className='icon' id="imgcol"/>
                      <a href="tel:+45.5148.3608" className='email text-white'>+ 91.93366.13039</a>{" "}
                      <div className="pbmit-ihbox-contents">
                        {/* <img src="assest/images/phone-call.png" alt="" className='icon'/>
                <a href="tel:+45.5148.3608" className='email text-white'> + 91.93366.13040</a>{" "} <br /> */}
                        <img src="assest/images/empty-email.png" alt="" className='icon' id="imgcol"/>
                        <a href="info@cwiilgroup.com" className='email text-white'>sales@cwiilgroup.com</a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-5 pbmit-textcolor-white">
                <div className="pbmit-ihbox-inner">
                  <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                    <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                      <h2 className="pbmit-custom-heading">
                        Investors Contact On
                      </h2>
                    </div>
                    <div className="pbmit-ihbox-icon  pbmit-icon-skincolor">
                      <img src="assest/images/phone-call.png" alt="" className='icon' id="imgcol"/>
                      <a href="tel:+45.5148.3608" className='email text-white'>+ 91.93366.13040</a>{" "}
                      <div className="pbmit-ihbox-contents">
                        <img src="assest/images/empty-email.png" alt="" className='icon' id="imgcol"/>
                        <a href="info@cwiilgroup.com" className='email text-white'>investments@cwiilgroup.com</a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ihbox End */}
      {/* About Start */}
      <section className="section-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="about-two-img-box">
                <figure>
                  <img
                    src="assest/images/media.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </figure>
                <div className="pbmit-fidbox-style-2">
                  <div className="pbmit-fld-contents">
                    <div className="pbmit-ihbox-icon pbmit-large-icon">
                      <div className="pbmit-sbox-icon-wrapper">
                        <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-balance" />
                      </div>
                    </div>
                    <div className="pbmit-fld-contents-wrap">
                      <div className="pbmit-fid-inner">
                        <span
                          data-appear-animation="animateDigits"
                          data-from={0}
                          data-to={98}
                          data-interval={5}
                          className="numinate"
                        >
                          98
                        </span>
                        <sub>%</sub>
                      </div>
                      <h3 className="pbmit-fid-title">
                        <span>
                          SUCCESS RATE
                          <br />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="about-two-content">
                <div className="pbmit-heading-subheading">
                  {/* <h4 className="pbmit-subtitle">Why Choose us</h4> */}
                  <h2 className="pbmit-title">Legal Eagles :  Multi Speciality Law Firm</h2>
                </div>
                <div className="pbmit-firstlater">
                  <p>
                    The &amp; Legal Eagles Global Corporation along with its entire team of eminent lawyers, jurists, Chartered Accountants, Retired Bankers, and top level Executives have the pleasure to introduce ourselves to you, as a complete full-fledged and self reliant Unit of CWIIL GROUP of Companies.

                    The Legal Eagles Global Corp. have ready made and effective solutions rather to solve all types of legal problems, tussles and bristling controversies/disputes raging in the various Tribunals and courts with the blessings of the Almighty, Elders and well Wishers We are introducing complete legal solutions/remedies/ pleadings, irrespective of the gravity of the legal problems involved in all these legal battles. &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className='text-black'>
              <ul>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp; Contract disputes / breach of contract</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Obligations disputes</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp; Property disputes or damages</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Succession disputes</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Car accidents</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Class action matters</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Administrative law matters</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Business disputes</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Commercial law disputes</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Complaints against the city when the city has declined settlement</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Temporary restraining orders</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Minor’s Compromise</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Financial fraud</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Bank robbery</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Counterfeiting</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Kidnapping</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Threatening the president or other federal officials or buildings</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Committing a crime on federal property</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Committing a crime using interstate commerce</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Committing a crime that involves a conspiracy</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Using a firearm to commit a crime</li>
                <li><img src="assest/images/check-mark.png" className="check" alt="" />&nbsp;Manufacturing and distributing controlled substances</li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section className="section-lg pbmit-bg-color-blackish">
        <div className="container">
          <div className="pbmit-heading-subheading text-center text-white">
            <h4 className="pbmit-subtitle">Fee As per the package
            </h4>
            <h2 className="pbmit-title">Core Values</h2>
          </div>
          <div className="service-arrow swiper-btn-custom d-flex flex-row-reverse">
            <div
              className="swiper-slider"
              data-arrows-class="service-arrow"
              data-loop="true"
              data-autoplay="true"
              data-dots="false"
              data-arrows="true"
              data-columns={4}
              data-margin={30}
              data-effect="slide"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {/* Slide1 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-service-content">
                              <p>
                                KAIZEN, which in layman terms means Constant Improvement , is our driving force, as Change is the only constant in the world. Adherence to Ethical, Legal, Socio-Environmental Values while Getting To and Addressing the Root of the Clients’ [ Government, Private or Non Profit Organisation ] Requirements, and after Proper Evaluation an Integrated Approach is taken to Ensure Results on a One-Time or Continuation Basis, as the External and Internal Business Environment is Dynamic or Changing Constantly.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide2 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-law-book" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Education Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide3 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-legal" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Business Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide4 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-briefcase" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Real Estate law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide5 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-lawyer-1" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Civil Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide6 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-gun" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Criminal Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide7 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-team" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Car Accident
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide8 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-lawyer-3" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Employment Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="swiper-slide">
                  {/* Slide9 */}
                  <article className="pbmit-servicebox-style-1">
                    <div className="pbmit-post-item">
                      <div className="pbmit-box-content">
                        <div className="pbmit-box-content-inner">
                          <div className="pbmit-pf-box-title">
                            <div className="pbmit-ihbox-icon">
                              <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-legal-document" />
                            </div>
                            <h3>
                              <a href="practice-areas-detail.html" tabIndex={0}>
                                Banking Law
                              </a>
                            </h3>
                            <div className="pbmit-service-content">
                              <p>
                                Post a question and get free advice from our
                                experienced lawyers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* **************Jagriti******************** */}
      {/* Service End */}
      {/* About Us Start */}
      <section className="about-us-two">
        <div className="container">
          <div className="about-us-section-two">
            <div className="row">
              <div className="col-md-8">
                <div className="pbmit-ihbox-style-1">
                  <div className="pbmit-ihbox-inner">
                    <div className="pbmit-ihbox-heading-with-icon">
                      <div className="pbmit-ihbox-icon ">
                        <div className="pbmit-ihbox-icon-wrapper">
                          <i className="pbmit-attorco-business-icon pbmit-attorco-business-icon-lawyer" />
                        </div>
                      </div>
                      <div className="pbmit-ihbox-heading">
                        <div className="pbmit-vc_general pbmit-vc_cta3 pbmit-cta3-only">
                          <div className="pbmit-vc_cta3_content-container">
                            <div className="pbmit-vc_cta3-content">
                              <div className="pbmit-vc_cta3-content-header pbmit-wrap">
                                <div className="pbmit-vc_cta3-headers pbmit-wrap-cell">
                                  <h2 className="pbmit-custom-heading ">
                                    Let us help you! Call Now
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pbmit-ihbox-contents">
                          <div className="pbmit-cta3-content-wrapper">
                            Get a free case evaluation. Call now +1-800-234-1345
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="about-us-two-btn">
                  <a
                    href="homepage-2.html"
                    className="pbmit-btn pbmit-btn-blackish"
                  >
                    Get In Touch
                    <i className="themifyicon ti-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Legal;