import React from 'react';
import './Navbar.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AppNavbar = () => {
  return (
    <Navbar bg="white" expand="lg">
      <Navbar.Brand href="/" className='head'>Dr.Pradeep Singh</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto text-dark">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link><Link to="/aboutus">About Us</Link></Nav.Link>
          <NavDropdown title="Services" id="basic-nav-dropdown">
            <NavDropdown.Item><Link to="/media">Media</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/legal">Legal</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/techlabs">Tech Labs</Link></NavDropdown.Item>
            {/* <NavDropdown.Divider /> */}
            <NavDropdown.Item><Link to="/astroguru">Astro Guru</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/pestcontrol">Pest Control</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/consultancy">Consultancy</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/InfrastructureProject">Infrastructure & Projects</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/investments">Investments & Wealth Management</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/newalternative">New Age Alternative Energy Technologies</Link></NavDropdown.Item>
            {/* <NavDropdown.Divider /> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/5">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/6">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/7">Something else here</NavDropdown.Item>
            </NavDropdown> */}
          </NavDropdown>
          <Nav.Link><Link to="/contactus">Contact Us</Link></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
