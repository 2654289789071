import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomeSlider.css'

function HomeSlider(){

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const arrowStyles = {
        width: '50px',  // Adjust the width as needed
        height: '50px', // Adjust the height as needed
    };
    return(
        <>
            <Slider {...settings}>
            <div>
                <img src="assest\images\banner1.jpg"  width="100%" alt="Slide 1" />
            </div>
            <div>
                <img src="assest\images\banner1.jpg"  width="100%" alt="Slide 2" />
            </div>
            {/* <div>
                <img src="assest/revolution/images/slider-6-04.jpg"  width="100%" alt="Slide 3" />
            </div> */}
            {/* Add more slides with images as needed */}
        </Slider>

        <style>{`
                .slick-prev,
                .slick-next {
                    width: ${arrowStyles.width};
                    height: ${arrowStyles.height};
                }
            `}</style>



<div className="pbmit-slider-area">
  {/* START Homeslider6 REVOLUTION SLIDER 6.6.12 */}
  <p className="rs-p-wp-fix" />
  <rs-module-wrap
    id="rev_slider_7_1_wrapper"
    data-alias="homeslider6"
    data-source="gallery"
    style={{
      visibility: "hidden",
      background: "transparent",
      padding: 0,
      margin: "0px auto",
      marginTop: 0,
      marginBottom: 0
    }}
  >
    <rs-module id="rev_slider_7_1" data-version="6.6.12">
      <rs-slides style={{ overflow: "hidden", position: "absolute" }}>
        <rs-slide
          style={{ position: "absolute" }}
          data-key="rs-1"
          data-title="Slide"
          data-thumb="revolution/images/slider-6-01-100x50.jpg"
          data-anim="adpr:false;p:dark;f:center;"
          data-in="o:0;sx:1.1;sy:1.1;m:true;row:30;col:30;"
          data-out="a:false;"
        >
          {/*
           */}
          <rs-layer
            id="slider-1-slide-1-layer-0"
            data-type="image"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:171px,171px,171px,89px;"
            data-text="w:normal;s:20,20,20,7;l:0,0,0,9;"
            data-dim="w:93px,93px,93px,37px;h:110px,110px,110px,44px;"
            data-frame_1="st:900;sp:1500;"
            data-frame_999="o:0;st:w;"
            style={{ zIndex: 8 }}
          >
          </rs-layer>
          {/*

								*/}
          <rs-layer
            id="slider-1-slide-1-layer-1"
            data-type="text"
            data-color="#1a2955"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:284px,284px,284px,134px;"
            data-text="s:80,80,80,40;l:80,80,80,50;a:inherit;"
            data-frame_0="x:-100%;o:1;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:900;sp:1500;sR:900;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:6600;"
            style={{ zIndex: 5, fontFamily: '"Crimson Text"' }}
          >
            Dedicated To One <br />
            Client At A Time.
          </rs-layer>
          {/*

								*/}
          <rs-layer
            id="slider-1-slide-1-layer-2"
            data-type="text"
            data-color="#1a2955"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:461px;"
            data-text="s:22;l:36;a:inherit;"
            data-vbility="t,t,t,f"
            data-frame_0="x:-100%;o:1;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:1690;sp:1500;sR:1690;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:5810;"
            style={{ zIndex: 6, fontFamily: '"Montserrat"' }}
          >
            Smart approaches to legal solution service.
          </rs-layer>
          {/*

								*/}
          <rs-layer
            id="slider-1-slide-1-layer-5"
            className="rev-btn"
            data-type="button"
            data-color="#1a2955"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:530px,530px,530px,257px;"
            data-text="s:13;l:24;ls:1px;fw:600;a:inherit;"
            data-padding="t:12;r:35;b:12;l:35;"
            data-border="bos:solid;boc:#1a2955;bow:2,2,2,2;"
            data-frame_1="st:2650;sp:500;sR:2650;"
            data-frame_999="o:0;st:w;sR:5850;"
            data-frame_hover="c:#fff;bgc:#1a2955;boc:#1a2955;bor:0px,0px,0px,0px;bos:solid;bow:2,2,2,2;oX:50;oY:50;sp:0;"
            style={{
              zIndex: 7,
              backgroundColor: "rgba(0,0,0,0)",
              fontFamily: '"Montserrat"',
              cursor: "pointer",
              outline: "none",
              boxShadow: "none",
              boxSizing: "border-box",
              MozBoxSizing: "border-box",
              WebkitBoxSizing: "border-box"
            }}
          >
            CONSULTATION
            <i className="slider-button-icon  pbmit-base-icon-right" />
          </rs-layer>
          {/*
           */}{" "}
        </rs-slide>
        <rs-slide
          style={{ position: "absolute" }}
          data-key="rs-3"
          data-title="Slide"
          data-thumb="revolution/images/slider-6-02-100x50.jpg"
          data-anim="adpr:false;p:dark;f:center;"
          data-in="o:0;sx:1.1;sy:1.1;m:true;row:30;col:30;"
          data-out="a:false;"
        >
          {/*
           */}
          <rs-layer
            id="slider-1-slide-3-layer-0"
            data-type="image"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:171px,171px,171px,89px;"
            data-text="w:normal;s:20,20,20,7;l:0,0,0,9;"
            data-dim="w:93px,93px,93px,37px;h:110px,110px,110px,44px;"
            data-frame_1="st:900;sp:1500;"
            data-frame_999="o:0;st:w;"
            style={{ zIndex: 8 }}
          >
          </rs-layer>
          {/*

								*/}
          <rs-layer
            id="slider-1-slide-3-layer-1"
            data-type="text"
            data-color="#1a2955"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:284px,284px,284px,134px;"
            data-text="s:80,80,80,40;l:80,80,80,50;a:inherit;"
            data-frame_0="x:-100%;o:1;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:900;sp:1500;sR:900;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:6600;"
            style={{ zIndex: 5, fontFamily: '"Crimson Text"' }}
          >
            Dedicated To One <br />
            Client At A Time.
          </rs-layer>
          {/*

								*/}
          <rs-layer
            id="slider-1-slide-3-layer-2"
            data-type="text"
            data-color="#1a2955"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:461px;"
            data-text="s:22;l:36;a:inherit;"
            data-vbility="t,t,t,f"
            data-frame_0="x:-100%;o:1;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:1690;sp:1500;sR:1690;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:5810;"
            style={{ zIndex: 6, fontFamily: '"Montserrat"' }}
          >
            Smart approaches to legal solution service.
          </rs-layer>
          {/*

								*/}
          <rs-layer
            id="slider-1-slide-3-layer-5"
            className="rev-btn"
            data-type="button"
            data-color="#1a2955"
            data-rsp_ch="on"
            data-xy="xo:15px;yo:530px,530px,530px,257px;"
            data-text="s:13;l:24;ls:1px;fw:600;a:inherit;"
            data-padding="t:12;r:35;b:12;l:35;"
            data-border="bos:solid;boc:#1a2955;bow:2,2,2,2;"
            data-frame_1="st:2650;sp:500;sR:2650;"
            data-frame_999="o:0;st:w;sR:5850;"
            data-frame_hover="c:#fff;bgc:#1a2955;boc:#1a2955;bor:0px,0px,0px,0px;bos:solid;bow:2,2,2,2;oX:50;oY:50;sp:0;"
            style={{
              zIndex: 7,
              backgroundColor: "rgba(0,0,0,0)",
              fontFamily: '"Montserrat"',
              cursor: "pointer",
              outline: "none",
              boxShadow: "none",
              boxSizing: "border-box",
              MozBoxSizing: "border-box",
              WebkitBoxSizing: "border-box"
            }}
          >
            CONSULTATION
            <i className="slider-button-icon  pbmit-base-icon-right" />
          </rs-layer>
          {/*
           */}{" "}
        </rs-slide>
      </rs-slides>
    </rs-module>
  </rs-module-wrap>
  {/* END REVOLUTION SLIDER */}
</div>



        </>
    );
}

export default HomeSlider;